import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { form_config } from '~/utils/models'

const getDefaultState = () => {
  return {
    staffs: [],
    templates: [],
    latest_clients: [],
    selectedClient: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },

  setStaffs(state, staffs) {
    state.staffs = staffs
  },
  setTemplates(state, templates) {
    state.templates = templates
  },
  setClient(state, client) {
    state.selectedClient = client

    // リスト追加
    let new_clients = _.filter(state.latest_clients, (cli) => { return cli.id !== client.id })
    new_clients.unshift(client)
    // 20超えたら銭湯削除
    if(new_clients.length > 20) new_clients.splice(-1);
    state.latest_clients = new_clients
  },
  
}
export const getters = {
}
export const actions = {
  async getStaffs(context){
    await httpClient
    .get('/cmp/account/staffs.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setStaffs", res.data.data.staffs)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
    .finally(() => {
      window.storeCtl.commit("loading/stop")
    })
  },
  async getClient(context, uid){
    await httpClient
    .get(`/cmp/floor/clients/${uid}.json`)
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setClient", res.data.data.client)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
    .finally(() => {
      window.storeCtl.commit("loading/stop")
    })
  },
  async getTemplates(context){
    await httpClient
    .get('/cmp/floor/templates.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setTemplates", res.data.data.templates)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
    .finally(() => {
      window.storeCtl.commit("loading/stop")
    })
  },

}
