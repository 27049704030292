import https from 'https'
import axios from 'axios'
import { isDev } from '~/utils/env'

let options = {}
if (isDev()) {
  options = {
    baseURL: 'http://api.crowler-dev.com:8888',
  }
} else {
  options = {
    baseURL: 'https://mtask-api.saucer-saas.com',
  }
}
const httpClient = axios.create(options)
export { httpClient }