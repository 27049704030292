import _ from 'lodash';

export let form_config = {
  headers: {
    "content-type": "multipart/form-data",
  }
}  

export let staff = {
  name: null,
  email: null,
  role: "member",
}


export let client = {
  id: null,
  name: null,
  email: null,
}

let task_data = {
  id: null,
  showAllOption: false,
  disable_to_client: false,
  name: null,
  memo: null,
  answer: null,
  fix_memo: null,
  require_answer: true,
  settings: {
    cp_doc_ids: [],
    cl_doc_ids: [],
  },
  use_before_alert: true,
  due_start_date: null,
  limit_date: null,
  use_priset_answer: false,
  useMemo: false,

  new_cp_docs: [],
  new_cl_docs: [],
}

let task_set_data = {
  id: null,
  disable_to_client: false,
  name: null,
  memo: null,
  answer: null,
  inner_memo: null,
  hide_memo: false,
  has_many_tasks: true,
  settings: {
    cp_doc_ids: [],
    cl_doc_ids: [],
  },
  use_before_alert: true,
  auto_close: true,
  limit_date: null,
  tasks: [_.cloneDeep(task_data)],

  new_cp_docs: [],
  new_cl_docs: [],
}

export let template = {
  id: null,
  title: null,
  memo: null,
  template_type: "task_set",
  settings: _.cloneDeep(task_set_data),
}

export let task = task_data
export let task_set = task_set_data
