import axios from "axios";

async function check(store){

  // ログイン時のみ対応
  if(store.getters['auth/isLogin']){
    // デターの同期チェック
    store.dispatch("auth/getUpdate")

    // トラッキングコード同期
    store.dispatch("tracking_code/sync")

    // oneSignal
    store.dispatch("auth/updateOneSignalDeviseToken")
  } // ログイン 

  // アプリ・バージョンアップ
  try {
    // version.jsonファイルから最新のバージョンを取得
    const res = await axios.get("/version.json");
    const data = res.data;
    const latestVersion = data.version;

    // 環境変数から現在のバージョンを取得
    const curVersion = !!process.env.VERSION ? Number(process.env.VERSION) : 0;
    // 最新バージョンよりも古かったら、強制的にリロードする
    if (curVersion < latestVersion){
      window.location.reload(true);
    }else{
    }
  } catch(error) {
    console.error(error);
    // エラーのときはなにもしない
  }
}

// まずSettimeoutで初期チェック、その後1分毎に自動チェック
export default async ({store, route, query}) => {

  // トラッキングコードセット
  // トラッキングコードがなければOtherにセット
  if(route.name === "user-uuid"){
    store.commit("tracking_code/setCode", `c_${route.params.uuid}`)
  }else if(route.name === "post-uuid"){
    store.commit("tracking_code/setCode", `p_${route.params.uuid}`)
  }else if(route.name === "tag-name"){
    store.commit("tracking_code/setCode", `t_${route.params.name}`)
  }else if(query.tc){
    store.commit("tracking_code/setCode", query.tc)
  }else{
    store.commit("tracking_code/setCode", `other`)
  }

  // check ios
  const ua = process.server
    ? context.req.headers['user-agent']
    : navigator.userAgent
  // cloudfrontでuseragentが書き換わるのでlambda edgeのviewer requestでPokke_iOS_Appの時は独自ヘッダーをつけて判別する
  const isApp = process.server
    ? context.req.headers['iOS_App'] === '1'
    : false
  // iOSアプリのUA
  // https://github.com/saucer-inc/pokke_ios/blob/16c7926e6834f880117c471eaca2cde4c5ede97f/Web/ViewController.swift#L41-L42
  store.commit('userAgent/set', {
    isIosApp: !!ua.match(/iOS_App/) || isApp
  })

  // 起動時だと処理できない箇所があったので５秒後
  window.setTimeout(async () => {

    // onesignal
    console.log("start get Onesignal")
    $OneSignal.push(async () => {
      console.log("start Onesignal subscriptionChange")
      await $OneSignal.on('subscriptionChange', async (isSubscribed) => {
        console.log("Onesignal subscriptionChange done", isSubscribed)
        if (isSubscribed) {
          const deviceId = await $OneSignal.getUserId()
          console.log("onesignel deviceId", deviceId)
          localStorage.setItem('onesignal_user_id', deviceId); 
        }else{
          $OneSignal.showNativePrompt();
        }
      })
    })
    $OneSignal.push(async () => {
      console.log("start Onesignal isPushNotificationsEnabled")
      await $OneSignal.isPushNotificationsEnabled(async (isEnabled) => {
        console.log("Onesignal isPushNotificationsEnabled done", isEnabled)
        if (isEnabled){
          const deviceId = await $OneSignal.getUserId()
          console.log("onesignel deviceId", deviceId)
          localStorage.setItem('onesignal_user_id', deviceId); 
        }else{
          $OneSignal.showNativePrompt();
        }
      })
    })

    // バナーチェック
    // if(!store.state.devise.firstContactDone) store.commit("modal/setFirstContactModal", true)

    // 諸々のチェック
    check(store)
  }, 1000 * 5);

  // 5分に1回
  window.setInterval(() => {check(store)}, 1000 * 60 * 5);
}