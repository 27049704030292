import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  createPersistedState({
    paths: [
      'devise',
      'modal',
      'auth',
      'company',
    ],  // localStorageに保存したいstoreを入れる
  })(store);
};