import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a440f94 = () => interopDefault(import('../pages/clients/index.vue' /* webpackChunkName: "pages/clients/index" */))
const _20bdf5d2 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _59463367 = () => interopDefault(import('../pages/templates/index.vue' /* webpackChunkName: "pages/templates/index" */))
const _32c71b56 = () => interopDefault(import('../pages/login/deputy.vue' /* webpackChunkName: "pages/login/deputy" */))
const _597504f2 = () => interopDefault(import('../pages/login/forget_id.vue' /* webpackChunkName: "pages/login/forget_id" */))
const _7a095b2c = () => interopDefault(import('../pages/login/re_password.vue' /* webpackChunkName: "pages/login/re_password" */))
const _2102e5f5 = () => interopDefault(import('../pages/login/singup.vue' /* webpackChunkName: "pages/login/singup" */))
const _22e74c55 = () => interopDefault(import('../pages/settings/alert.vue' /* webpackChunkName: "pages/settings/alert" */))
const _3daddf6e = () => interopDefault(import('../pages/settings/alerts.vue' /* webpackChunkName: "pages/settings/alerts" */))
const _3d303727 = () => interopDefault(import('../pages/settings/card.vue' /* webpackChunkName: "pages/settings/card" */))
const _0f1622d6 = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _5e87e1b2 = () => interopDefault(import('../pages/settings/password.vue' /* webpackChunkName: "pages/settings/password" */))
const _219ac032 = () => interopDefault(import('../pages/settings/payment_histories.vue' /* webpackChunkName: "pages/settings/payment_histories" */))
const _ef496c3c = () => interopDefault(import('../pages/settings/profile.vue' /* webpackChunkName: "pages/settings/profile" */))
const _4a700f2c = () => interopDefault(import('../pages/settings/staffs.vue' /* webpackChunkName: "pages/settings/staffs" */))
const _4b8ce018 = () => interopDefault(import('../pages/settings/team.vue' /* webpackChunkName: "pages/settings/team" */))
const _0f7db875 = () => interopDefault(import('../pages/templates/new.vue' /* webpackChunkName: "pages/templates/new" */))
const _10f44320 = () => interopDefault(import('../pages/clients/_uid/index.vue' /* webpackChunkName: "pages/clients/_uid/index" */))
const _41477268 = () => interopDefault(import('../pages/docs/_filter.vue' /* webpackChunkName: "pages/docs/_filter" */))
const _0c168dfe = () => interopDefault(import('../pages/task_sets/_filter.vue' /* webpackChunkName: "pages/task_sets/_filter" */))
const _3089bfc1 = () => interopDefault(import('../pages/tasks/_filter.vue' /* webpackChunkName: "pages/tasks/_filter" */))
const _035fd10c = () => interopDefault(import('../pages/templates/_uid.vue' /* webpackChunkName: "pages/templates/_uid" */))
const _9e774b06 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/clients/",
    component: _6a440f94,
    pathToRegexpOptions: {"strict":true},
    name: "clients"
  }, {
    path: "/login/",
    component: _20bdf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/templates/",
    component: _59463367,
    pathToRegexpOptions: {"strict":true},
    name: "templates"
  }, {
    path: "/login/deputy/",
    component: _32c71b56,
    pathToRegexpOptions: {"strict":true},
    name: "login-deputy"
  }, {
    path: "/login/forget_id/",
    component: _597504f2,
    pathToRegexpOptions: {"strict":true},
    name: "login-forget_id"
  }, {
    path: "/login/re_password/",
    component: _7a095b2c,
    pathToRegexpOptions: {"strict":true},
    name: "login-re_password"
  }, {
    path: "/login/singup/",
    component: _2102e5f5,
    pathToRegexpOptions: {"strict":true},
    name: "login-singup"
  }, {
    path: "/settings/alert/",
    component: _22e74c55,
    pathToRegexpOptions: {"strict":true},
    name: "settings-alert"
  }, {
    path: "/settings/alerts/",
    component: _3daddf6e,
    pathToRegexpOptions: {"strict":true},
    name: "settings-alerts"
  }, {
    path: "/settings/card/",
    component: _3d303727,
    pathToRegexpOptions: {"strict":true},
    name: "settings-card"
  }, {
    path: "/settings/email/",
    component: _0f1622d6,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email"
  }, {
    path: "/settings/password/",
    component: _5e87e1b2,
    pathToRegexpOptions: {"strict":true},
    name: "settings-password"
  }, {
    path: "/settings/payment_histories/",
    component: _219ac032,
    pathToRegexpOptions: {"strict":true},
    name: "settings-payment_histories"
  }, {
    path: "/settings/profile/",
    component: _ef496c3c,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile"
  }, {
    path: "/settings/staffs/",
    component: _4a700f2c,
    pathToRegexpOptions: {"strict":true},
    name: "settings-staffs"
  }, {
    path: "/settings/team/",
    component: _4b8ce018,
    pathToRegexpOptions: {"strict":true},
    name: "settings-team"
  }, {
    path: "/templates/new/",
    component: _0f7db875,
    pathToRegexpOptions: {"strict":true},
    name: "templates-new"
  }, {
    path: "/clients/:uid/",
    component: _10f44320,
    pathToRegexpOptions: {"strict":true},
    name: "clients-uid"
  }, {
    path: "/docs/:filter?/",
    component: _41477268,
    pathToRegexpOptions: {"strict":true},
    name: "docs-filter"
  }, {
    path: "/task_sets/:filter?/",
    component: _0c168dfe,
    pathToRegexpOptions: {"strict":true},
    name: "task_sets-filter"
  }, {
    path: "/tasks/:filter?/",
    component: _3089bfc1,
    pathToRegexpOptions: {"strict":true},
    name: "tasks-filter"
  }, {
    path: "/templates/:uid/",
    component: _035fd10c,
    pathToRegexpOptions: {"strict":true},
    name: "templates-uid"
  }, {
    path: "/",
    component: _9e774b06,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
