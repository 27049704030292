//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Common from '~/mixins/common'
import UserIcon from '~/components/util/UserIcon'
import Alert from '~/components/util/Alert'
import Loading from '~/components/util/Loading'
import ConfirmDialog from '~/components/util/ConfirmDialog'
import ModalFirstContact from '~/components/common/ModalFirstContact'

export default {
  name: "default",
  mixins: [Common],
  components: {
    Alert,
    Loading,
    ConfirmDialog,
    UserIcon,
    ModalFirstContact,
  },
  data() {
    return {
      showSettingModal: false,
      version: !!process.env.VERSION ? Number(process.env.VERSION) : 0,
    }
  },
  async mounted() {
    let self = this
    self.$nuxt.$root.$confirm = this.$refs.confirm

    // detect devise
    if(self.$mq === 'pc'){
      console.log("this is pc")
      this.$store.commit("devise/setDrawer", true)
      this.$store.commit("devise/setIsPC", true)   
    }else{
      console.log("this is sp")
      this.$store.commit("devise/setIsPC", false) 
    }

  },
  wath: {
    selected_pdf_open(){

    },
  },
  computed: {
    drawer: {
      get(){
        return this.$store.state.devise.drawer
      },
      set(newValue){
        this.$store.commit("devise/setDrawer", newValue)
      }
    },
  },
  methods: {
    clickDrower(){
      this.$store.commit("devise/setDrawer", !this.$store.state.devise.drawer)
    },
    async reaload(){
      let self = this
      self.$store.commit("loading/start")

      // ここで一度マスターをすべて取得
      await self.$store.dispatch("auth/getUpdate")
      await self.$store.dispatch('auth/getStaff'),
      await self.$store.dispatch('auth/getCompany'),
      await self.$store.dispatch("auth/updateOneSignalDeviseToken")
      await window.storeCtl.dispatch('company/getStaffs')
      await window.storeCtl.dispatch('company/getTemplates')

      self.$store.commit("loading/stop")
    }
  },
}
