export const CommonModalFirstContact = () => import('../../components/common/ModalFirstContact.vue' /* webpackChunkName: "components/common-modal-first-contact" */).then(c => wrapFunctional(c.default || c))
export const UtilAlert = () => import('../../components/util/Alert.vue' /* webpackChunkName: "components/util-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilCard = () => import('../../components/util/Card.vue' /* webpackChunkName: "components/util-card" */).then(c => wrapFunctional(c.default || c))
export const UtilConfirmDialog = () => import('../../components/util/ConfirmDialog.vue' /* webpackChunkName: "components/util-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const UtilEditorView = () => import('../../components/util/EditorView.vue' /* webpackChunkName: "components/util-editor-view" */).then(c => wrapFunctional(c.default || c))
export const UtilImageFileInput = () => import('../../components/util/ImageFileInput.vue' /* webpackChunkName: "components/util-image-file-input" */).then(c => wrapFunctional(c.default || c))
export const UtilImageThumbnail = () => import('../../components/util/ImageThumbnail.vue' /* webpackChunkName: "components/util-image-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const UtilLoading = () => import('../../components/util/Loading.vue' /* webpackChunkName: "components/util-loading" */).then(c => wrapFunctional(c.default || c))
export const UtilPageHeader = () => import('../../components/util/PageHeader.vue' /* webpackChunkName: "components/util-page-header" */).then(c => wrapFunctional(c.default || c))
export const UtilTour = () => import('../../components/util/Tour.vue' /* webpackChunkName: "components/util-tour" */).then(c => wrapFunctional(c.default || c))
export const UtilUserIcon = () => import('../../components/util/UserIcon.vue' /* webpackChunkName: "components/util-user-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonClientEdit = () => import('../../components/common/client/Edit.vue' /* webpackChunkName: "components/common-client-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonClientEditMemo = () => import('../../components/common/client/EditMemo.vue' /* webpackChunkName: "components/common-client-edit-memo" */).then(c => wrapFunctional(c.default || c))
export const CommonClientList = () => import('../../components/common/client/List.vue' /* webpackChunkName: "components/common-client-list" */).then(c => wrapFunctional(c.default || c))
export const CommonClientMenu = () => import('../../components/common/client/Menu.vue' /* webpackChunkName: "components/common-client-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonClientMessage = () => import('../../components/common/client/Message.vue' /* webpackChunkName: "components/common-client-message" */).then(c => wrapFunctional(c.default || c))
export const CommonDocList = () => import('../../components/common/doc/List.vue' /* webpackChunkName: "components/common-doc-list" */).then(c => wrapFunctional(c.default || c))
export const CommonDocLists = () => import('../../components/common/doc/Lists.vue' /* webpackChunkName: "components/common-doc-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonStaffMessage = () => import('../../components/common/staff/Message.vue' /* webpackChunkName: "components/common-staff-message" */).then(c => wrapFunctional(c.default || c))
export const CommonStaffSelector = () => import('../../components/common/staff/Selector.vue' /* webpackChunkName: "components/common-staff-selector" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskDetail = () => import('../../components/common/task/Detail.vue' /* webpackChunkName: "components/common-task-detail" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskList = () => import('../../components/common/task/List.vue' /* webpackChunkName: "components/common-task-list" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskLists = () => import('../../components/common/task/Lists.vue' /* webpackChunkName: "components/common-task-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskNew = () => import('../../components/common/task/New.vue' /* webpackChunkName: "components/common-task-new" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskStatusChip = () => import('../../components/common/task/StatusChip.vue' /* webpackChunkName: "components/common-task-status-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskSetDetail = () => import('../../components/common/task_set/Detail.vue' /* webpackChunkName: "components/common-task-set-detail" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskSetEdit = () => import('../../components/common/task_set/Edit.vue' /* webpackChunkName: "components/common-task-set-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskSetList = () => import('../../components/common/task_set/List.vue' /* webpackChunkName: "components/common-task-set-list" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskSetLists = () => import('../../components/common/task_set/Lists.vue' /* webpackChunkName: "components/common-task-set-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskSetMenu = () => import('../../components/common/task_set/Menu.vue' /* webpackChunkName: "components/common-task-set-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskSetNew = () => import('../../components/common/task_set/New.vue' /* webpackChunkName: "components/common-task-set-new" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskSetStatusChip = () => import('../../components/common/task_set/StatusChip.vue' /* webpackChunkName: "components/common-task-set-status-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonTemplateEdit = () => import('../../components/common/template/Edit.vue' /* webpackChunkName: "components/common-template-edit" */).then(c => wrapFunctional(c.default || c))
export const UtilDocsDocument = () => import('../../components/util/docs/Document.vue' /* webpackChunkName: "components/util-docs-document" */).then(c => wrapFunctional(c.default || c))
export const UtilDocsDocumentInputs = () => import('../../components/util/docs/DocumentInputs.vue' /* webpackChunkName: "components/util-docs-document-inputs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
