import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import Compressor from 'compressorjs'

export default {
  computed: {
    ...mapState({
      userAgent: state => state.userAgent,
      isPC: state => state.devise.isPC,
      isLogin: state => !!state.auth.authToken,
      new_alert_size: state => state.auth.new_alert_size,
      company: state => state.auth.company,
      staff: state => state.auth.staff,
      submited_tasks: state => state.auth.submited_tasks,
      staffs: state => state.company.staffs,
      templates: state => state.company.templates,
      selectedClient: state => state.company.selectedClient,
    }),
    currentPlan(){
      return _.find(this.company.plans, ["type", this.company.plan_type])
    },
  },
  methods: {
    setTaskSetLimitDate(type){
      if(type === "3") return dayjs().add(3,'d').format()
      if(type === "7") return dayjs().add(7,'d').format()
      if(type === "this_weekend") return dayjs().endOf('week').format()
      if(type === "next_weekend") return dayjs().add(1,'w').endOf('week').format()
      if(type === "this_monthend") return dayjs().endOf('month').format()
      if(type === "next_monthend") return dayjs().add(1,'M').endOf('month').format()
    },
    limitDateOver(limit_date){
      if(!limit_date) return false
      let today = dayjs()
      return today.isAfter(dayjs(limit_date))
    },
    autoLink(text) {
      return _.isString(text) ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank'>$1</a>") : '';
    },
    copyToClipborad(text){
      this.$copyText(text).then(function (e) {
        window.storeCtl.commit("alert/setSuccess", "コピーしました")
      }, function (e) {
        // alert('Can not copy')
      })      
    },

    // document
    filterDocs(docs, doc_ids){
      return _.filter(docs, (doc)=>{return _.includes(doc_ids, doc.id)})
    },
    async getDocuments(document_ids){
      let self = this
      let documents = []
      documents = await httpClient
        .get('/cmp/floor/docs.json', {params: {doc_ids: doc_ids}})
        .then(async (res) => {
          if (res.data.status === 'success') return res.data.data.docs
        })
      return documents
    },

  }
}
