import Vue from 'vue';
import { isDev } from '~/utils/env'

export default ({store, isHMR}) => {

  if (isHMR) return

  if(store.state.userAgent.isIosApp){
    console.log("this is ios so skip payjp")
    return
  }

  if (process.client) {
    let payjp_pk = isDev() ? "pk_test_ce4b9de243b7ecc504bae3d7" : "pk_live_ceef1708a2b961882304a201"
    // 起動時だと処理できない箇所があったので５秒後
    window.setTimeout(async () => {
      console.log("payjp do")
      Vue.prototype.$payjp = window.Payjp(payjp_pk)
    }, 1000 * 2);

  }
}