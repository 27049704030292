const getDefaultState = () => {
  return {
    devise_token: null,
    isPC: true,
    drawer: false,

    firstContactDone: false,
    showFirstContactModal: false,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  setDeviseToken(state, devise_token) {
    state.devise_token = devise_token
  },
  setIsPC(state, boolean) {
    state.isPC = boolean
  },
  setDrawer(state, drawer) {
    state.drawer = drawer
  },  

  firstContactDone(state) {
    state.firstContactDone = true
  },
  setFirstContactModal(state, payload) {
    state.showFirstContactModal = payload
  },
}